<template>
  <div>
    <h1 class="title is-1">Dokumentumjegyzék</h1>
    <div v-if="isLoaded">
      <b-collapse
        class="card ml-2"
        animation="slide"
        v-for="(documentRegisterBySurvey, index) of documentRegisters"
        :key="index"
        :open="true"
        :aria-id="'docSummaryDocumentRegisterBySurvey-' + index">
        <template #trigger="props">
          <div
            class="card-header has-background-primary"
            role="button"
            :aria-controls="'docSummaryDocumentRegisterBySurvey-' + index"
            :aria-expanded="props.open"
          >
            <p class="card-header-title has-text-white">
              {{ documentRegisterBySurvey.dokumentumjegyzek_nev }}
            </p>
            <a class="card-header-icon">
              <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
              </b-icon>
            </a>
          </div>
        </template>
        <div class="card-content">
          <div class="content">
            <b-table :data="documentRegisterBySurvey.dokumentumjegyzek"
                     class="has-table-success-header" striped>
              <b-table-column label="Kérdésszám"
                              field="question_number"
                              width="50"
                              v-slot="props">
                {{ props.row.question_number }}
              </b-table-column>
              <b-table-column label="Megjegyzés"
                              field="name"
                              v-slot="props">
                {{ props.row.document }}
              </b-table-column>
              <b-table-column label="Csatolt dokumentumok"
                              field="attached_document"
                              width="600"
                              v-slot="props">
                <div v-for="(document, index) in props.row.documents"
                     :key="`summanry_document_${document.id}_${index}`"
                     class="border-bottom">
                  <span class="is-inline-block dr-document-name">{{ document.name }}</span>
                  <div class="buttons is-inline-block is-pulled-right">
                    <b-button type="is-ghost"
                              icon-left="eye"
                              @click="openOrDownloadFile(document.id)"
                              class="text-button-height-modifier">
                    </b-button>
                    <b-button type="is-ghost"
                              icon-left="file-download"
                              @click="openOrDownloadFile(document.id, 'download')"
                              class="text-button-height-modifier">
                    </b-button>
                    <b-button @click="deleteFileFromDocumentRegister(document.id, props.row.question_id)"
                              icon-left="trash"
                              class="text-button-height-modifier has-text-danger"
                              type="is-ghost">
                    </b-button>
                  </div>
                </div>
              </b-table-column>
            </b-table>
          </div>
        </div>
      </b-collapse>
    </div>
    <div v-else
         class="loading-container">
      <Loading :is-active="true"/>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading'

export default {
  name: 'InspectionSurvey',
  components: { Loading },
  data () {
    return {
      documentRegisters: [],
      isLoaded: false,
    }
  },
  methods: {
    async openOrDownloadFile (documentID, action = 'open') {
      this.isLoading = true
      if (['download', 'open'].includes(action)) {
        await this.$store.dispatch('downloadFileFromDocumentRegister', {
          documentID: documentID,
          isDownload: action === 'download',
        })
      }
      this.isLoading = false
    },
    async deleteFileFromDocumentRegister (documentID, questionID) {
      alert('TODO: deleteFileFromDocumentRegister (frissítés + hibaüzenet)')
      await this.$store.dispatch('openModal', {
        modalName: 'ConfirmationModal',
        data: {
          text: 'Biztos törli a dokumentumot?',
          confirmFunction: async () => {
            this.isLoading = true
            await this.$store.dispatch('deleteFileFromDocumentRegister', {
              questionID: questionID,
              documentID: documentID,
            })
            await this.refreshDocumentRegister()
            this.isLoading = false
          },
        },
      })
    },
    async refreshDocumentRegister () {
      this.isLoaded = false
      const resp = await this.$store.dispatch('fetchDocumentRegisterSummary', this.$route.params.inspectionID)
      if (resp) {
        this.documentRegisters = resp
      }
      this.isLoaded = true
    }
  },
  async created () {
    this.isLoaded = false
    const resp = await this.$store.dispatch('fetchDocumentRegisterSummary', this.$route.params.inspectionID)
    if (resp) {
      this.documentRegisters = resp
    }
    this.isLoaded = true
  },
}
</script>

<style scoped>
.dr-document-name {
  max-width: 420px;
}
</style>
